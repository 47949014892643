import React from 'react'
import './TextInput.css'


const TextInput = (props) => {
    return (
        <div style={{maxWidth:props.maxWidth?props.maxWidth:"100%",width:props.width?props.width:"100%",marginRight:props.marginRight?props.marginRight:null,padding:props.padding?props.padding:0,marginLeft:props.marginLeft?props.marginLeft:null,backgroundColor:props.backgroundColor?props.backgroundColor:"#ffff",borderBottom:props.borderBottom?props.borderBottom:"none"}} className='inputHolder'>
            {props.img?
            <img src={props.img}></img>
            :null}
            {props.icon?props.icon:null}
            <input id={props.id} onKeyDown={(e)=>{
                if(props.onKeyDown){
                    props.onKeyDown(e)
                }
                
            }} /* onFocus={()=>{props.onFocus()}} onBlur={()=>{props.onBlur()}} */  disabled={props.disabled} placeholder={props.placeholder} style={{ textAlign: props.align,backgroundColor:props.backgroundColor?props.backgroundColor:"#ffff",fontWeight:props.fontWeight?props.fontWeight:"400",fontSize:props.fontSize?props.fontSize:24,letterSpacing:props.letterSpacing?props.letterSpacing:'normal',color:props.color?props.color:"#fffff" }} value={props.value} className="input" type={props.type} onChange={(e) =>{ props.onChange(e.target.value)
           
            }}></input>

        </div>

    )
}



export default TextInput