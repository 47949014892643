import * as EN from './en'

const Locale=()=>{
   let lang=Object.keys(EN).reduce((agg,curr)=>{agg[curr]=EN[curr]
    return agg
    },{})

    return lang
}

export default Locale()

