import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import TextInput from "../../Components/TextInput";
import "./Header.css";
import { connect } from "react-redux";
import * as Actions from "../../Global/Actions";
import * as Toast from "../../Global/Toasts";
import { BsFillMoonStarsFill, BsFillSunFill, BsSearch } from "react-icons/bs";
import logo from "./wrapABBC.svg";
import Modal from "../../Components/Modal";
import * as API from '../../Global/Utility'
import { useNavigate } from "react-router-dom";
import locale from "../../Global/Locale/index";

const Header = (props) => {
  const [account, setAccount] = useState("")
  const [modal, setModal] = useState(false);
  const [req, setReq] = useState("");
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(null)
  let navigate = useNavigate();

  const getStatus = async () => {
    setLoading(true)
    try {
      if (req) {
        let res = await API.getStatus({ request_id: req })
        if (res.data.code === 20005) {
          setStatus(res.data.data.message)
          if (window.innerWidth > 400) {
            await API.BrowserNotificationHandler("Status", res.data.data.message)

          }
          setStatusCode(res.data.code)
          setLoading(false)
          return
        }
        if (window.innerWidth > 400) {

          await API.BrowserNotificationHandler("Status", res.data.data.message)
        }
        setStatus(res.data.data.message)
        setStatusCode(res.data.code)
        setLoading(false)
      }
      else {
        setLoading(false)
        Toast.warningToast("Please provide the request ID")
      }


    } catch (error) {
      setLoading(false)
      Toast.errorToast(error.message)
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        setAccount(accounts[0])
        props.setAdd(accounts[0]);
      });
    } else {
      Toast.warningToast("Please install Metamask.")
    }
  }, []);

  const changeMode = () => {
    localStorage.setItem("mode", props.dark ? "light" : "dark");
    props.setDark(!props.dark);
  };
  const statusContent = (
    <div className={props.dark ? "swap_status dark" : "swap_status"}>
      <h1>{locale.swap_status}</h1>
      <TextInput
        color={props.dark ? "#ffff" : "#231F20"}
        backgroundColor={props.dark ? "#152348" : "#ffff"}
        padding={10}
        fontSize={16}
        borderBottom={props.dark ? "1px solid #f7991c" : "1px solid #231F20"}
        placeholder={locale.req_id}
        value={req}
        onChange={(val) => {
          setReq(val);
        }}
      ></TextInput>
      {status ? <p>
        <div
          style={{
            background: statusCode === 20005 || statusCode === 20013 ? "red" : (statusCode === 20011 ? "#ffa90c" : statusCode === 20012 ? "green" : "red"),
            borderRadius: "50%",
            width: 12,
            height: 12,
            display: "inline-block",
            marginRight: 5,
          }}
        >
          {" "}
        </div>
        {status}
      </p> : null}
      <Button
        onClick={() => { getStatus() }}
        fontSize={20}
        fontWeight="400"
        color="#231F20"
        backgroundColor="#FFB048"
        margin="20px 0px"
        label={locale.check_status}
        loader={loading}
        disabled={loading}
      ></Button>
    </div>
  );
  const walletConnectHandler = async () => {
    if (window.ethereum) {
      if (window.ethereum.isCoin98) {
        Toast.errorToast("Please disable Coin98 extension")
      } else {
        try {
          let check = API.redirectToMetamask()
          if (!check) {
            let res = await window.ethereum.enable()
            console.log(res)
            setAccount(res[0])
            props.setAdd(res[0]);
          }
        } catch (error) {
          Toast.warningToast("Please install Metamask and connect your wallet.");
        }
      }
    }
  }
  return (
    <>
      <div className={props.dark ? "Header dark" : "Header"}>
        <div className="container-fluid">
          <div className="row nav_holder">
            <div className="col-2">
              <div className="main_logo">
                <a onClick={() => { navigate('/') }}>
                  <img
                    src={
                      logo
                    }
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-10">
              <div className="metamask_connector">
                {/* <input className="status_input" placeholder="Search Request"></input> */}
                {window.innerWidth < 600 ? <BsSearch onClick={() => { setModal(true) }} size={20} style={{ marginRight: 20, cursor: "pointer" }} color={props.dark ? "#ffff" : "#231F20"}></BsSearch> : <TextInput value={req} onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setModal(true)
                  }
                }} onChange={(val) => {
                  setReq(val)
                }} icon={<BsSearch size={20} style={{ marginRight: 10 }} color={props.dark ? "#ffff" : "#231F20"}></BsSearch>} marginRight="20px" width="200px" color={props.dark ? "#ffff" : "#231F20"} backgroundColor={props.dark ? '#131a36' : "#ffff"}
                  padding={10} fontSize={16} borderBottom={props.dark ? '1px solid #f7991c' : '1px solid #231F20'} placeholder={locale.search_request}></TextInput>}

                <img
                  src={`${process.env.PUBLIC_URL}/Images/metamask-icon.png`}
                ></img>
                <Button
                  color="#231F20"
                  width="auto"
                  label={
                    props.publicAdd ? props.publicAdd.slice(0, 6) + "..." + props.publicAdd.slice(-6) : locale.connect_wallet
                  }
                  onClick={walletConnectHandler}
                ></Button>
                {props.dark ? (
                  <div>
                    <BsFillMoonStarsFill
                      onClick={changeMode}
                      className="darkmodeicon dark"
                      size={20}
                    ></BsFillMoonStarsFill>
                  </div>
                ) : (
                  <div>
                    <BsFillSunFill
                      onClick={changeMode}
                      className="darkmodeicon"
                      size={20}
                    ></BsFillSunFill>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalIsOpen={modal}
        content={statusContent}
        close={() => {
          setLoading(false)
          setReq("")
          setStatus("")
          setModal(false);
        }}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    publicAdd: state.publicAdd,
    dark: state.dark,
  };
};
const mapActionsToProps = (dispatch) => {
  return {
    setAdd: (val) => {
      dispatch(Actions.setAdd(val));
    },
    setDark: (val) => {
      dispatch(Actions.setDark(val));
    },
  };
};
export default connect(mapStateToProps, mapActionsToProps)(Header);
