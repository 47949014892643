import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import {AiOutlineClose} from 'react-icons/ai'


const Modal=({modalIsOpen,content,dark,close})=>{
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-45%',
      transform: 'translate(-50%, -50%)',
      background: dark?"#131a36":"#ffffff",
      border:"1px solid #f7991c",
      zIndex:"99",
      width: "30%",
      minWidth: 350

    }
  };
return(
<ReactModal
        isOpen={modalIsOpen}
        style={customStyles}
      >
      <div style={{display:"flex",justifyContent:"flex-end"}}>
        {close?<AiOutlineClose style={{cursor:"pointer"}} onClick={()=>{close()}} size={20} color='#f7991c'></AiOutlineClose>:null}
        </div>
          {content}
      </ReactModal>
)
}


const mapStateToProps = (state) => {
  return ({
      publicAdd: state.publicAdd,
      dark: state.dark
  })
}

export default connect(mapStateToProps)(Modal)