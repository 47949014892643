export const network_list = {
    "BEP20": {
        rpcurl: "https://bsc-dataseed1.binance.org/",
        chainID: "0x38",
        chainName: "Binance Mainnet",
        tokenName: "BABBC",
        localName: "WABBC",
        network: "BEP20",
        contract: "0xe83cE6bfb580583bd6A62B4Be7b34fC25F02910D",
        logo: "wrapABBC.png"
    },
    "ERC20": {
        rpcurl: "https://bsc-dataseed1.binance.org/",
        chainID: "0x38",
        chainName: "Binance Mainnet",
        tokenName: "BABBC",
        localName: "WABBC",
        network: "ERC20",
        contract: "0xe83cE6bfb580583bd6A62B4Be7b34fC25F02910D",
        logo: "wrapABBC.svg"
    }
}
export const ABBC = {
    tokenName: "ABBC",
    localName: "ABBC",
    logo:"abbc_logo.png"
}