import React from 'react'
import './Success.css'
import { BiArrowBack, BiCopyAlt } from 'react-icons/bi'
import * as Toast from '../../Global/Toasts'
import { connect } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router';
import copy from 'copy-to-clipboard';
import Tick from './tick.svg'
import locale from "../../Global/Locale/index";
const Success = (props) => {
    const { state } = useLocation();
    let navigate = useNavigate();
    if(!state || !state.req_id){
        return <Navigate to='/'></Navigate>
    }
    return (
        <div className={props.dark?'Success dark':"Success"}>
            <div className="container-fluid">
            <img src={Tick/* `${process.env.PUBLIC_URL}${props.dark?"/Images/Success.png":"/Images/Success.png"}` */}></img>
            <h1>{locale.Trans_Complete}</h1>
            <p>{locale.Success_msg}</p>
            <div className='requestid_holder'>
                <h4>{locale.Request_ID}</h4>
                <div className='request_id'>
                    <p>{state.req_id}</p>
                    <div className="copy_holder"><BiCopyAlt size={25} onClick={() => {
                        copy(state.req_id)
                            Toast.successToast("Copied")
                        
                        
                    }} color='#B2B2B2'></BiCopyAlt></div>
                </div>
                <p>{locale.Success_info}</p>
            </div>
            </div>
         </div>
    )
                
}
const mapStateToProps = (state) => {
    return ({
        publicAdd: state.publicAdd,
        dark:state.dark
    })
}

export default connect(mapStateToProps)(Success)