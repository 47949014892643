import React, { useEffect, useState } from 'react'
import TextInput from '../../Components/TextInput'
import './TransScreen.css'
import QRCode from "react-qr-code";
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { connect } from 'react-redux';
import Button from '../../Components/Button';
import * as API from '../../Global/Utility'
import Axios from 'axios';
import { BiArrowBack, BiCopyAlt } from 'react-icons/bi'
import * as Toast from '../../Global/Toasts'
import copy from 'copy-to-clipboard';
import Modal from '../../Components/Modal';
import { ImQrcode } from 'react-icons/im'
import ReactTooltip from 'react-tooltip'
import locale from "../../Global/Locale/index";
// import {network_list} from '../../Global/Networks'
const TransScreen = (props) => {
    let network_list = props.networks
    const [trxid, setTrx] = useState("")
    const [exact_amount, setexact_amount] = useState("")
    const [receivable_amount, setreceivable_amount] = useState("")
    const [deposit_currency, setdeposit_currency] = useState("")
    const [TSSadd, setTSSadd] = useState("")
    const [memo, setMemo] = useState("")
    const [receipent_address, setreceipent_address] = useState("")
    const [network, setnetwork] = useState("")
    const [loader, setLoader] = useState(false)
    const [trxError, setTrxError] = useState(false)
    const [urlModal, setURLModal] = useState(false)
    const [tooltip, setToolTip] = useState(false)
    let navigate = useNavigate();
    let location = useLocation()
    let paramsURL = useParams()
    let request_id = paramsURL.id
    const getReqDetails = async () => {
        try {
            let req_data = await API.getRequestDet({ request_id: paramsURL.id })
            if (req_data.data.code === 1) {
                navigate('/success', { state: { req_id: request_id } })
                return
            }
            if (req_data.data.code === 0) {
                let data = req_data.data.data
                setMemo(data.memo)
                setexact_amount(data.exact_amount)
                setreceivable_amount(data.receivable_amount)
                setdeposit_currency(data.deposit_currency)
                setTSSadd(data["TSS address"])
                setreceipent_address(data.receipent_address)
                setnetwork(data.network)
                props.setNetwork(data.network)
                return
            } else {
                Toast.errorToast(req_data.data.data.status)
                navigate('/')
            }

        } catch (error) {
            Toast.errorToast(error.message)
        }
    }
    useEffect(() => {
        getReqDetails()
    }, [])
    const submit = async () => {
        setLoader(true)
        try {
            if (paramsURL.id && receipent_address && trxid) {
                let params = { receipent_address, exact_amount, receivable_amount, request_id, "TSS address": TSSadd, deposit_currency: deposit_currency === "ABBC" ? deposit_currency : "WABBC", network, memo, trx_id: trxid }
                let submitRes = await API.submit(params)
                if (submitRes.data.code === 20007) {
                    // Toast.errorToast("Invalid Transaction ID")
                    setTrxError("Invalid Transaction ID")
                    setLoader(false)
                    return
                }
                if (submitRes.data.code === 20004 || submitRes.data.code === 20006) {
                    // Toast.errorToast("Transaction ID already existis.")
                    setTrxError("Transaction ID already exists.")
                    setLoader(false)
                    return
                }
                if (submitRes.data.code === 20005) {
                    // Toast.errorToast("Invalid Request ID")
                    setTrxError("Invalid Request ID")
                    setLoader(false)
                    return
                }
                if (submitRes.data.code === 20008) {
                    if (window.innerWidth > 400) {
                        await API.BrowserNotificationHandler("Fail", submitRes.data.data.message)
                    }
                    navigate('/fail', { state: { req_id: request_id, message: submitRes.data.data.message } })
                    // Toast.errorToast("Invalid Request ID")
                    // setTrxError(false)
                    setLoader(false)
                    return
                }
                if (submitRes.data.code === 0) {
                    if (window.innerWidth > 400) {
                        await API.BrowserNotificationHandler("Success", "Your swap request has been successfully processed. The transfer may still be in pending. Please check your wallet.")
                    }
                    navigate('/success', { state: { req_id: request_id } })
                    setLoader(false)
                    return
                } else {
                    Toast.errorToast(submitRes.data.data.message)
                    setTrxError(false)
                    setLoader(false)
                }

            }
            else {
                // Toast.errorToast("Please provide the TXID")
                setTrxError(true)
                setLoader(false)

            }
        } catch (error) {
            Toast.errorToast(error.message)
            // setTrxError(true)
            setLoader(false)
        }

    }
    // const addToken = async () => {
    //     try {
    //         let check = API.redirectToMetamask()
    //         if (!check) {
    //             const { token_contract_Address, tokenName, logo_url } = props.networks[network]
    //             // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    //             const tokenAddress = token_contract_Address;
    //             const tokenSymbol = tokenName;
    //             const tokenDecimals = 8;
    //             const tokenImage = logo_url;
    //             const wasAdded = await window.ethereum.request({
    //                 method: 'wallet_watchAsset',
    //                 params: {
    //                     type: 'ERC20', // Initially only supports ERC20, but eventually more!
    //                     options: {
    //                         address: tokenAddress, // The address that the token is at.
    //                         symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
    //                         decimals: tokenDecimals, // The number of decimals in the token
    //                         image: "https://i.postimg.cc/6qCdVZjM/wrapABBC.png", // A string url of the token logo
    //                     },
    //                 },
    //             });

    //             if (wasAdded) {
    //                 console.log('Token already added.');
    //             }
    //         }
    //     } catch (error) {
    //         Toast.errorToast(error.message);
    //     }
    // }
    if (!paramsURL.id) {
        return (<Navigate to={'/'}></Navigate>)

    }
    return (
        <>
            {deposit_currency === "ABBC" ? <div className='trans_holder'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='transleft_holder'>
                                <div className={props.dark ? 'back_button dark' : "back_button"} onClick={() => { navigate("/") }}><BiArrowBack color='#231F20'></BiArrowBack><p >{locale.Back}</p></div>
                                <div className={props.dark ? 'send_addressholder dark' : 'send_addressholder'}>
                                    <h1>{locale.send_exact} <span>{exact_amount} <BiCopyAlt size={20} onClick={() => {
                                        copy(exact_amount)
                                        Toast.successToast("Copied")
                                    }} color='#B2B2B2'></BiCopyAlt></span> {deposit_currency} {locale.to}</h1>
                                    <div className={props.dark ? 'sender_address dark' : "sender_address"}>
                                        <div className={props.dark ? 'sender_logoholder dark' : "sender_logoholder"}>
                                            <img src={`${process.env.PUBLIC_URL}/Images/abbc_logo.png`}></img>
                                            <p>{TSSadd}</p>
                                        </div>
                                        <BiCopyAlt size={25} onClick={() => {
                                            copy(TSSadd)
                                            Toast.successToast("Copied")
                                            // navigator.clipboard.writeText(TSSadd).then(()=>{
                                            //     Toast.successToast("Copied")
                                            // })
                                        }} color='#B2B2B2'></BiCopyAlt>
                                    </div>
                                    <div className={props.dark ? 'qr_holder dark' : 'qr_holder'}>
                                        <p>{locale.trans_desc_1} <span>{receivable_amount}</span> {network && network_list[network].tokenName} {locale.to} {receipent_address.length > 12 ? (receipent_address).slice(0, 4) + "..." + (receipent_address).slice(-4) : receipent_address} {locale.trans_desc_2}</p>
                                        <div className='qr_border'><QRCode size='100' value={`abbc:${TSSadd}?amount=${exact_amount}`} ></QRCode></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='transright_holder'>
                                <div className={props.dark ? 'memo_holder dark' : "memo_holder"}>
                                    <h1>{locale.Memo}</h1>
                                    <div className='memo'>
                                        <p>{memo}</p>
                                    </div>
                                    <p>{locale.Memo_info_1} {network && network_list[network].tokenName} {locale.Memo_info_2}</p>
                                </div>
                                <div className={props.dark ? 'txid_holder dark' : "txid_holder"}>
                                    <h1>{locale.TXID}</h1>

                                    <Modal close={() => { setURLModal(false) }} modalIsOpen={urlModal} content={(<div className={props.dark ? 'txid_holder dark' : 'txid_holder'}>
                                        <h1>URL</h1>
                                        <div className={props.dark ? 'sender_address dark' : "sender_address"}>
                                            <div className={props.dark ? 'sender_logoholder dark' : "sender_logoholder"}>
                                                <img src={`${process.env.PUBLIC_URL}/Images/abbc_logo.png`}></img>
                                                <p>{`${window.location.origin}/trans/${request_id}`}</p>
                                            </div>
                                            <BiCopyAlt size={25} onClick={() => {
                                                copy(`${window.location.origin}/trans/${request_id}`)
                                                Toast.successToast("Copied")
                                            }} color='#B2B2B2'></BiCopyAlt>
                                        </div>
                                        <div className='qr_holder'>
                                            <p>Please make sure you copy the URL if you wish to come back and finish the transfer later.</p>
                                            <div className='qr_border'><QRCode size='100' value={`${window.location.origin}/trans/${request_id}`} ></QRCode></div>
                                        </div>

                                    </div>)}></Modal>

                                    <div className={trxError ? 'txid_inputholder error' : "txid_inputholder"}>
                                        <TextInput placeholder={locale.TXID_placeholder} marginRight={10} color={props.dark ? "#ffff" : "#231F20"} backgroundColor={props.dark ? '#152348' : "#ffff"} error={trxError} fontSize={16} onChange={(val) => {
                                            setTrx(val)
                                        }}></TextInput>
                                        <ReactTooltip place="top" id='qr' type='light' effect='solid'>
                                            {/* <div className='qr_border'> */}<QRCode size='100' value={`${window.location.origin}/trans/${request_id}`} ></QRCode>{/* </div> */}
                                        </ReactTooltip>
                                        <ImQrcode onMouseEnter={() => { setToolTip(true) }} onMouseLeave={() => { setToolTip(false) }} data-tip data-for='qr' color={props.dark ? "#ffff" : "#000000"} /* onClick={() => { setURLModal(true) }} */ size={30} />
                                    </div>
                                    {trxError ? <p className='errorText'>{trxError}</p> : null}
                                    <p>{locale.TXID_info_1}<br></br>
                                        <span>{locale.TXID_info_2}</span></p>
                                </div>
                                <Button disabled={loader} loader={loader} fontSize={20} fontWeight="700" color='#231F20' backgroundColor="#FFB048" label={locale.Submit} onClick={() => { submit() }}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : (deposit_currency !== "ABBC" && deposit_currency !== "" ? <div className='trans_holder'>
                <div className='container'>

                    <div className='trans_holder_wabbc'>
                        <div className={props.dark ? 'back_button dark' : "back_button"}>
                            <div style={{ display: "flex", alignItems: "center" }} onClick={() => { navigate("/") }}><BiArrowBack color='#231F20'></BiArrowBack><p>{locale.Back}</p></div>
                            {/* <div onClick={() => { addToken() }} style={{ marginLeft: "auto", cursor: "pointer" }}><p><img style={{ width: 15, position: "relative", top: -1, marginRight: 5 }} src={`${process.env.PUBLIC_URL}/Images/metamask-icon.png`}></img>Add Token</p></div> */}
                        </div>
                        <div className={props.dark ? 'send_addressholder dark' : 'send_addressholder'}>
                            <h1>{locale.send_exact} <span>{exact_amount} <BiCopyAlt size={20} onClick={() => {
                                copy(exact_amount)
                                Toast.successToast("Copied")
                            }} color='#B2B2B2'></BiCopyAlt></span> {network && network_list[network].tokenName} {locale.to}</h1>
                            <div className={props.dark ? 'sender_address dark' : "sender_address"}>
                                <div className={props.dark ? 'sender_logoholder dark' : "sender_logoholder"}>
                                    <img src={`${process.env.PUBLIC_URL}/Images/abbc_logo.png`}></img>
                                    <p>{`${TSSadd.slice(0, 25)}...`}</p>
                                </div>
                                <BiCopyAlt size={25} onClick={() => {
                                    copy(TSSadd)
                                    Toast.successToast("Copied")
                                }} color='#B2B2B2'></BiCopyAlt>
                            </div>
                            <div className={props.dark ? 'qr_holder dark' : 'qr_holder'}>
                                <p>{locale.trans_desc_1} <span>{receivable_amount}</span> ABBC to {receipent_address ? (receipent_address.length > 12 ? (receipent_address).slice(0, 10) + "..." : receipent_address) : ""} {locale.trans_desc_2}</p>
                                <div className='qr_border'><QRCode size='100' value={`abbc:${TSSadd}?amount=${exact_amount}`} ></QRCode></div>
                            </div>
                        </div>

                        <div className={props.dark ? 'txid_holder dark' : "txid_holder"}>
                            <h1>{locale.TXID}</h1>
                            <Modal close={() => { setURLModal(false) }} modalIsOpen={urlModal} content={(<div className={props.dark ? 'txid_holder dark' : 'txid_holder'}>
                                <h1>{locale.URL}</h1>
                                <div className={props.dark ? 'sender_address dark' : "sender_address"}>
                                    <div className={props.dark ? 'sender_logoholder dark' : "sender_logoholder"}>
                                        <img src={`${process.env.PUBLIC_URL}/Images/abbc_logo.png`}></img>
                                        <p>{`${window.location.origin}/trans/${request_id}`}</p>
                                    </div>
                                    <BiCopyAlt size={25} onClick={() => {
                                        copy(`${window.location.origin}/trans/${request_id}`)
                                        Toast.successToast("Copied")
                                    }} color='#B2B2B2'></BiCopyAlt>
                                </div>
                                <div className='qr_holder'>
                                    <p>{locale.URL_info}</p>
                                    <div className='qr_border'><QRCode size='100' value={`${window.location.origin}/trans/${request_id}`} ></QRCode></div>
                                </div>
                            </div>)}></Modal>
                            <div className={trxError ? 'txid_inputholder error' : "txid_inputholder"}>
                                <TextInput color={props.dark ? "#ffff" : "#231F20"} placeholder={locale.TXID_placeholder} backgroundColor={props.dark ? '#152348' : "#ffff"} fontSize={16} onChange={(val) => {
                                    setTrx(val)
                                }}></TextInput>
                                <ReactTooltip place="right" id='qr' type='light' effect='solid'>
                                    {/* <div className='qr_border'> */}<QRCode size='100' value={`${window.location.origin}/trans/${request_id}`} ></QRCode>{/* </div> */}
                                </ReactTooltip>
                                <ImQrcode onMouseEnter={() => { setToolTip(true) }} onMouseLeave={() => { setToolTip(false) }} data-tip data-for='qr' color={props.dark ? "#ffff" : "#000000"} /* onClick={() => { setURLModal(true) }} */ size={30} />

                            </div>
                            {trxError ? <p className='errorText'>{trxError}</p> : null}
                            <p>{locale.TXID_info_1}<br></br>
                                <span>{locale.TXID_info_2}</span></p>
                        </div>
                        <Button disabled={loader} loader={loader} fontSize={20} fontWeight="700" color='#231F20' backgroundColor="#FFB048" label={locale.Submit} onClick={() => { submit() }}></Button>
                    </div>
                </div>
            </div> : <div style={{ height: "500px", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}><div class="loader">Loading...</div></div>)}
        </>
    )




}



const mapStateToProps = (state) => {
    return ({
        publicAdd: state.publicAdd,
        dark: state.dark,
        networks: state.networks
    })
}
const mapActionsToProps = (dispatch) => {
    return (
        {
            setNetwork: (val) => { dispatch({ type: "SET_NETWORK", payLoad: val }) }
        }
    )
}
export default connect(mapStateToProps, mapActionsToProps)(TransScreen)