import React from 'react'
import { toast } from 'react-toastify';
let toastconfig={
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    hideProgressBar: true
    }
export const successToast=(message)=>{
    toast.success(message,toastconfig );
}
export const errorToast=(message)=>{
    toast.error(message,toastconfig );
}
export const warningToast=(message)=>{
    toast.warning(message,toastconfig );
}
