export const setAdd = (add) => {
    return {
        payLoad:add,
        type:"SET_ADD"
    }
}
export const setDark = (val) => {
    return {
        payLoad:val,
        type:"SET_DARK"
    }
}