import "./App.css";
import { Route, Routes } from "react-router";
import Home from "./Views/Home";
import TransScreen from "./Views/TransScreen";
import Header from "./Views/Header";
import Success from "./Views/Success";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swap from "./Views/Home/Swap";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Fail from "./Views/Fail";
import Web3 from "web3";
import { errorToast } from "./Global/Toasts";
import * as API from "./Global/Utility";
import Locale from './Global/Locale/index'
// import Web3Modal from "web3modal";
import OpenApp from "react-open-app";
import Modal from "./Components/Modal";
function App({ dark, network, getNetworks, network_list }) {
  const [loader, setLoader] = useState(true);
  const dispatch=useDispatch()
  const getNetworkList = async () => {
    try {
      let res = await API.getNetworks();
      if (res.data.code !== 0) {
        errorToast("Failed to fetch networks");
        return;
      }
      let networks = {};
      res.data.data.map((ele) => {
        networks[ele.network] = { ...ele };
      });
      console.log(networks)
      let localNetwork=localStorage.getItem("network")
      if(!(localNetwork in networks)){
        
        dispatch({type:"SET_NETWORK",payLoad:Object.keys(networks)[0]})
      }
      getNetworks({ ...networks });
    } catch (error) {
      errorToast(error.message);
    }
  };
  const getBrowserNoti=async()=>{
    await API.getPermissionNotification()
  }
  
  useEffect(() => {
    console.log(Locale)
    // console.log(Locale(),"sssss")
    getNetworkList();
  }, []);
  useEffect(() => {
   
    if (dark) {
      document.getElementsByTagName("body")[0].style.backgroundColor =
        "#131a36";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#ffff";
    }
    if (network in network_list) {
      window.web3 = new Web3(
        new Web3.providers.HttpProvider(network_list[network].node)
      );
    }
    if (Object.keys(network_list).length > 0) {
      setLoader(false);
      getBrowserNoti()
    }
  }, [dark, network, network_list]);
  const content=(
    (<div  style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",margin:"10px 0px",overflow:"hidden"}}>
      <p>Please open the app on metamask</p>
      <OpenApp href="https://metamask.app.link/dapp/dev-test-3000.abbcswap.com">Metamask</OpenApp>
    </div>)
  )
  // if (isMobileDevice() && !window.ethereum) {
  //   return (
  //     <Modal modalIsOpen={true} content={content} />

  //   )
  // }
  return (
    <>
      {loader ? (
        <div
          style={{
            height: "100vh",
            width:"100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow:"hidden"
          }}
        >
          <div class="loader">Loading...</div>
        </div>
      ) : (
        <>
          <Header></Header>
          {/* <div className='layout'> */}
          {/* <img className='logo'  src={`./Images/logo.jpg`}></img> */}
          <Routes>
            <Route path="/" element={<Home></Home>}></Route>
            <Route
              path="/trans/:id"
              element={<TransScreen></TransScreen>}
            ></Route>
            <Route path="/Success" element={<Success></Success>}></Route>
            <Route path="/fail" element={<Fail></Fail>}></Route>
            <Route path="/Swap" element={<Swap></Swap>}></Route>
            <Route path="*" element={<Home></Home>}></Route>
          </Routes>
          {/* </div> */}
          <ToastContainer limit={1} />
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    dark: state.dark,
    network: state.network,
    network_list: state.networks,
  };
};
const mapPropsToActions = (dispatch) => {
  return {
    getNetworks: (val) => {
      dispatch({ type: "SET_NETWORKS", payLoad: val });
    },
  };
};
export default connect(mapStateToProps, mapPropsToActions)(App);
