import React, { useRef, useState, useEffect } from "react";
import "./Home.css";
import Button from "../../Components/Button";
import TextInput from "../../Components/TextInput";
import CoinPicker from "../../Components/CoinPicker";
import * as API from "../../Global/Utility";
import { Resposne } from "../../Global/ResponseDesc";
import * as _ from "lodash";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Networks from "../../Components/Networks";
import { BiArrowBack } from "react-icons/bi";
import * as Toast from "../../Global/Toasts";
import { Coins } from "../../Global/Coins";

import * as Actions from "../../Global/Actions";
import BeatLoader from "react-spinners/BeatLoader";
import { formatUnits } from "@ethersproject/units";
import { ABBC } from "../../Global/Networks";
import { ABI } from '../../Global/ABI'
import locale from "../../Global/Locale/index";
import Swal from 'sweetalert2'

const ManualData = [
    {
        name: 'BSC',
        url: `${process.env.PUBLIC_URL}/Abbc_Swap_BSC.pdf`
    },
    {
        name: 'FTM',
        url: `${process.env.PUBLIC_URL}/Abbc_Swap_FTM.pdf`
    },
    {
        name: 'MATIC',
        url: `${process.env.PUBLIC_URL}/Abbc_Swap_MATIC.pdf`
    },
    {
        name: 'ETH',
        url: `${process.env.PUBLIC_URL}/Abbc_Swap_ETH.pdf`
    },
    {
        name: 'AVAX',
        url: `${process.env.PUBLIC_URL}/Abbc_Swap_AVAX.pdf`
    },
]

const Home = (props) => {
    let network_list = props.networks;
    let navigate = useNavigate();
    const [converted, setConverted] = useState(0.0);
    const [from, setFrom] = useState("ABBC");
    const [fromLabel, setFromLabel] = useState("ABBC");
    const [to, setTo] = useState(network_list[props.network].tokenName);
    const [toLabel, setToLabel] = useState(network_list[props.network].tokenName);
    const [gasFee, setGasFee] = useState("-");
    const amount = useRef(null);
    const [userWABBC, setUserWABBC] = useState("");
    const [loader, setLoader] = useState(false);
    const [gasError, setGasError] = useState("");
    const [addressErr, setAddressErr] = useState(false);
    const [rotate, setRotate] = useState(true);
    const [manual, setManual] = useState(false)
    const getTokenBalance = async () => {
        try {
            if (props.publicAdd) {
                let contract = new window.web3.eth.Contract(ABI, network_list[props.network].token_contract_Address)
                let balance = await contract.methods.balanceOf(props.publicAdd).call()
                return balance
            } else {
                Toast.errorToast("Please connect your wallet")
            }
        } catch (error) {
            Toast.errorToast(error.message)
        }
    }

    useEffect(() => {
        setFrom("ABBC")
        setFromLabel("ABBC")
        setTo(network_list[props.network].tokenName)
        setToLabel(network_list[props.network].tokenName)
    }, [props.network])

    const switchNetwork = async (val, status) => {
        if (window.ethereum) {
            if (!status) {
                if (window.ethereum.isCoin98) {
                    Toast.errorToast("Please disable Coin98 extension")
                } else {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: network_list[val].chainID }],
                        });
                        return true
                    } catch (switchError) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === 4902) {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: network_list[val].chainID,
                                            chainName: network_list[val].chainName,
                                            rpcUrls: [network_list[val].node],
                                            nativeCurrency: {
                                                name: network_list[val].metamask_symbol,
                                                symbol: network_list[val].metamask_symbol,// 2-6 characters long
                                                decimals: 18
                                            },
                                            blockExplorerUrls: [network_list[val].explorer_url]
                                        },
                                    ],
                                });
                            } catch (error) {
                                if (error.code === -32602) {
                                    await window.ethereum.request({
                                        method: "wallet_switchEthereumChain",
                                        params: [{ chainId: network_list[val].chainID }],
                                    }).then(res => { }).catch(e => {
                                        if (e.code == 4001) {
                                            Toast.errorToast(e.message)
                                        } else {
                                            return
                                        }
                                    });
                                    localStorage.setItem("network", val)
                                    props.setNetwork(val);
                                    convert(amount.current, from, val)
                                    return
                                }
                                if (error.code === -32002) {
                                    Toast.errorToast("Please confirm the pending request from metamask")
                                } else {
                                    Toast.errorToast(error.message)
                                }
                            }
                        }
                    }
                }
            } else {
                Toast.warningToast("Under Maintenance. Sorry for the inconvenience caused.")

            }

        } else {
            Toast.warningToast("Please install Metamask.")
        }
    };

    useEffect(() => {
        switchNetwork(props.network);
    }, [props.network]);
    const convert = _.debounce(async (val, depCurr, networkVal = props.network) => {
        try {
            setLoader(true);
            if (val === "") {
                setLoader(false);
            }
            if (!isNaN(Number(val))) {
                if (Number(val) === 0) {
                    setGasFee("-");
                    setConverted(0);

                    setLoader(false);
                    return;
                }
                // debugger
                let convertRes = await API.getConversion(
                    Number(val),
                    depCurr = depCurr === "ABBC" ? depCurr : "WABBC",
                    networkVal
                );
                if (convertRes.data.code === 0) {
                    setConverted(convertRes.data.data.receivable_amount);
                    setGasFee(Number(convertRes.data.data.total_fee).toFixed(2));
                    setLoader(false);
                    setGasError("");
                    return;
                }
                if (convertRes.data.code === 22023) {
                    Toast.errorToast(convertRes.data.data.message)
                    setLoader(false);
                    return;
                }
                if (convertRes.data.code === 22024) {
                    setGasError(convertRes.data.data.message)
                    setLoader(false);
                    return;
                }
                if (convertRes.data.code === 20020) {
                    // setGasError(convertRes.data.data.message)
                    Toast.errorToast(convertRes.data.data.message)

                    setLoader(false);
                    return;
                }
                if (convertRes.data.code === 20001) {
                    setGasError(convertRes.data.data.message);
                    setConverted(0.0);
                    setLoader(false);
                    return;
                } else {
                    Toast.warningToast(Resposne[convertRes.data.code].message);
                    setConverted(0.0);
                    setLoader(false);
                    setGasError("");
                }
            } else {
                setGasError("Invalid Input");
                setLoader(false);
            }
        } catch (error) {
            Toast.errorToast(error.message);
            setLoader(false);
            setGasError("");
        }
    }, 1000);

    const swap = async () => {
        // debugger;
        setLoader(true);
        try {
            if (!!!converted || isNaN(Number(converted))) {
                Toast.warningToast("Please enter valid amount");
                setLoader(false);
                return;
            }
            if (
                (from === "ABBC" && props.publicAdd) ||
                (from !== "ABBC" && userWABBC)
            ) {
                let deposit_currency;
                let network = props.network;
                let publicadd;
                Swal.fire({
                    title: '<strong>Warning!</u></strong>',
                    icon: 'warning',
                    html:
                        'Please do not send ABBC from any <b>EXCHANGE</b> when using ABBC Swap. Otherwise, you might lose your ABBC.',
                    focusConfirm: false,
                    confirmButtonText:
                        'Agree',
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        if (from === "ABBC") {
                            publicadd = window.web3.utils.toChecksumAddress(props.publicAdd);
                            deposit_currency = "ABBC";
                        } else {
                            publicadd = userWABBC;
                            deposit_currency = network_list[props.network].localName;
                        }

                        let swap = await API.swap(
                            Number(amount.current),
                            publicadd,
                            deposit_currency,
                            network
                        );

                        if (swap.data.code === 0) {
                            navigate(
                                `/trans/${swap.data.data.request_id}` /* , { state: { data: swap.data.data, coinid: from, deposit_currency, network } } */
                            );
                            setLoader(false);
                        }
                        if (swap.data.code === 20003) {
                            setAddressErr("Please enter valid wallet address");
                            setLoader(false);
                        } else {
                            Toast.errorToast(swap.data.data.message);
                            setLoader(false);
                        }
                    } else {
                        setLoader(false);
                    }
                }).catch(e => console.log(e))

            } else {
                if (from === "ABBC") {
                    Toast.errorToast("Please Connect your wallet");
                    setLoader(false);
                } else {
                    setAddressErr("Please enter valid wallet address");
                    setLoader(false);
                }
            }
        } catch (error) {
            Toast.errorToast(error.message);
            setLoader(false);
        }


    };

    const changeHandler = (val) => {
        if (rotate) {
            document.getElementsByClassName("swapicon_holder")[0].style.transform =
                "translate(50%, -50%) rotate(180deg)";
        } else {
            document.getElementsByClassName("swapicon_holder")[0].style.transform =
                "translate(50%, -50%) rotate(0deg)";
        }
        let dep;
        if (from === "ABBC") {
            setTo("ABBC");
            setToLabel("ABBC");
            setFrom(network_list[props.network].tokenName);
            setFromLabel(network_list[props.network].tokenName);
            dep = network_list[props.network].localName;
        } else {
            setTo(network_list[props.network].tokenName);
            setToLabel(network_list[props.network].tokenName);
            setFrom("ABBC");
            setFromLabel("ABBC");
            dep = "ABBC";
        }
        setRotate(!rotate);
        if (!isNaN(Number(amount.current)) && Number(amount.current) > 0) {
            convert(amount.current, dep);
        }
    };

    let { tokenName, localName, network, logo_url } = network_list[props.network];
    const walletConnectHandler = async () => {
        try {
            let res = await window.ethereum.enable();
            // setAccount(res[0])
            props.setAdd(res[0]);
        } catch (error) {
            Toast.warningToast("Please install Metamask and connect your wallet.");
        }
    };
    // useEffect(() => {
    //   if(window.ethereum){
    //     window.ethereum.on("accountsChanged", (accounts) => {
    //       //   setAccount(accounts[0])
    //       props.setAdd(accounts[0]);
    //     });
    //   }else{
    //     Toast.warningToast("Please install metamask to use.")
    //   }

    // }, []);
    function isMobileDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }

    const addToken = async () => {
        let userAgent = navigator.userAgent;
        let browserName;
        if (userAgent.match(/firefox|fxios/i)) {
            window.location.reload()
        }

        try {
            // debugger
            let check = API.redirectToMetamask()
            if (!check) {
                const { token_contract_Address, tokenName, logo_url } = props.networks[network]
                // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                const tokenAddress = token_contract_Address;
                const tokenSymbol = tokenName;
                const tokenDecimals = 8;
                const tokenImage = logo_url;
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                            address: tokenAddress, // The address that the token is at.
                            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                            decimals: tokenDecimals, // The number of decimals in the token
                            image: "https://i.postimg.cc/6qCdVZjM/wrapABBC.png", // A string url of the token logo
                        },
                    },
                });
            }
        } catch (error) {
            Toast.errorToast(error.message);
        }
    }
    return (
        <>
            <div className="home_holder">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className={props.dark ? "home_left dark" : "home_left"}>
                                <h1>
                                    {locale.title_1} <br></br> {locale.title_2}{" "}
                                    <span> <span style={{ textTransform: "capitalize" }}>{network_list[network].network_coin == "BNB" ? "Binance" : network_list[network].network_coin}</span> {locale.title_3} ({network_list[network].tokenName})</span>
                                </h1>

                                <p>
                                    {locale.subHeader_1}<br></br>
                                    <br></br> {locale.subHeader_21} <span style={{ textTransform: "capitalize" }}>{network_list[network].network_coin == "BNB" ? "Binance" : network_list[network].network_coin}</span> {locale.subHeader_22}
                                </p>
                                {window.innerWidth <= 992 ? (
                                    <div className="connect_walletholder">
                                        <Button
                                            margin="10px 0"
                                            fontSize={20}
                                            border="1px solid #FFB048"
                                            fontWeight="700"
                                            color="#231F20"
                                            backgroundColor="#FFFF"
                                            label={
                                                props.publicAdd ? locale.connected_wallet_home : locale.connect_wallet_home
                                            }
                                            onClick={() => {
                                                let check = API.redirectToMetamask()
                                                if (!check) {
                                                    walletConnectHandler();
                                                }
                                                // navigate("/trans");
                                            }}
                                        ></Button>
                                        <Button
                                            margin="10px 0"
                                            fontSize={20}
                                            fontWeight="700"
                                            color="#231F20"
                                            backgroundColor="#FFB048"
                                            label="SWAP NOW"
                                            onClick={() => {
                                                let check = API.redirectToMetamask()
                                                // swap()
                                                // if(isMobileDevice() || !window.ethereum){
                                                //     window.location.replace("https://metamask.app.link/dapp/front1.abbcswap.com/")
                                                // }
                                                if (!check) {
                                                    navigate("/swap");
                                                }
                                            }}
                                        ></Button>
                                    </div>
                                ) : null}
                                <div className="wabbc_features">
                                    <div
                                        className={
                                            props.dark ? "feature_icons dark" : "feature_icons"
                                        }
                                    >
                                        <img src="./Images/feature1.png"></img>
                                        <h2>{locale.homeIcon_1}</h2>
                                    </div>
                                    <div
                                        className={
                                            props.dark ? "feature_icons dark" : "feature_icons"
                                        }
                                    >
                                        <img src="./Images/feature2.png"></img>
                                        <h2>{locale.homeIcon_2}</h2>
                                    </div>
                                    <div
                                        className={
                                            props.dark ? "feature_icons dark" : "feature_icons"
                                        }
                                    >
                                        <img src="./Images/feature3.png"></img>
                                        <h2>{locale.homeIcon_3}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 992 ? null : (
                            <div className="col-lg-5">
                                <div className={props.dark ? "home_right dark" : "home_right"}>
                                    {/* <h1>{locale.Swap}</h1> */}
                                    <div className={props.dark ? 'back_button dark' : "back_button"}>
                                        <h1>{locale.Swap}</h1>
                                        <div onClick={() => { addToken() }} style={{ marginLeft: "auto", cursor: "pointer" }}><p><img style={{ width: 15, position: "relative", top: -1, marginRight: 5 }} src={`${process.env.PUBLIC_URL}/Images/metamask-icon.png`}></img>Add Token</p></div>
                                    </div>
                                    <div
                                        className={props.dark ? "coin_holder dark" : "coin_holder"}
                                    >
                                        <div
                                            className={props.dark ? "coin_item dark" : "coin_item"}
                                        >
                                            <div
                                                onClick={() => {
                                                    changeHandler();
                                                }}
                                                className="swapicon_holder"
                                            >
                                                <img src={"./Images/swap_icon.png"}/* "./Images/swap_icon.png" */></img>
                                            </div>
                                            {/* './Images/abbc_logo.png' */}
                                            <div className="coin_info">
                                                <div className="coin_icon">
                                                    <img
                                                        src={
                                                            from === "ABBC"
                                                                ? `./Images/${ABBC.logo}`
                                                                : `./Images/wrapABBC.png`
                                                        }
                                                    ></img>
                                                    <p>{fromLabel}</p>
                                                </div>
                                                <h4>{fromLabel}</h4>
                                            </div>
                                            <div className="coin_trans">
                                                <p>{locale.From}</p>
                                                <TextInput
                                                    id="input_swap"
                                                    marginLeft={0}
                                                    color={props.dark ? "#ffff" : "#231F20"}
                                                    backgroundColor={props.dark ? "#152348" : "#ffff"}
                                                    placeholder="0.00"
                                                    align="left"
                                                    onChange={(val) => {
                                                        amount.current = val;
                                                        if (from === "ABBC") {
                                                            convert(val, from);
                                                        } else {
                                                            convert(val, localName);
                                                        }
                                                    }}
                                                ></TextInput>
                                                {from !== "ABBC" ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={async () => {
                                                            let tokenBalance = await getTokenBalance()
                                                            // tokenBalance=Number(tokenBalance)
                                                            if (tokenBalance) {
                                                                document.getElementById("input_swap").value =
                                                                    formatUnits(
                                                                        tokenBalance,
                                                                        8
                                                                    ).toString();
                                                                amount.current = formatUnits(
                                                                    tokenBalance,
                                                                    8
                                                                );
                                                                convert(
                                                                    formatUnits(
                                                                        tokenBalance,
                                                                        8
                                                                    ).toString(),
                                                                    network_list[props.network].localName
                                                                );
                                                            } /* else {
                                                                Toast.warningToast(
                                                                    "Please connect to Metamask"
                                                                );
                                                            } */
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                color: "#f7991c",
                                                                fontSize: 14,
                                                                padding: "3px 10px",
                                                                border: "1px solid #f7991c",
                                                                borderRadius: 4,
                                                            }}
                                                        >
                                                            {locale.Max}
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div
                                            className={props.dark ? "coin_item dark" : "coin_item"}
                                            id="coin_itemswap_mob"
                                        >
                                            <div className="coin_info">
                                                <div className="coin_icon">
                                                    <img
                                                        src={
                                                            to === "ABBC"
                                                                ? `./Images/${ABBC.logo}`
                                                                : `./Images/wrapABBC.png`
                                                        }
                                                    ></img>
                                                    <p>{toLabel}</p>
                                                </div>
                                                <h4>{toLabel}</h4>
                                            </div>
                                            {/* if(!(!!converted) || isNaN(Number(converted)) ){ */}
                                            <div className="coin_trans">
                                                <p>{locale.To}</p>
                                                {!loader ? (
                                                    <h2>{Number(converted).toFixed(2)}</h2>
                                                ) : (
                                                    <BeatLoader
                                                        color="#f7991c"
                                                        loading={loader}
                                                        size={8}
                                                    ></BeatLoader>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {gasError ? <p className="errorText">{gasError}</p> : null}
                                    <div className="address_holder">
                                        <div className="network_holder">
                                            <p className="network_label">{locale.choose_network}</p>
                                            <p>{gasFee} ABBC {locale.gas_fee}</p>
                                        </div>
                                        <div className="networktype_holder">
                                            {Object.keys(network_list).map((ele) => {
                                                return (
                                                    <Networks
                                                        onClick={() => {
                                                            switchNetwork(ele, network_list[ele].maintenance);
                                                            props.setNetwork(ele);
                                                        }}
                                                        disabled={network_list[ele].disabled}
                                                        active={props.network === ele}
                                                        value={ele}
                                                        img={network_list[ele].logo_url}
                                                        chainName={network_list[ele].chainName}
                                                        maintenance={network_list[ele].maintenance}
                                                    ></Networks>
                                                );
                                            })}
                                        </div>
                                        {/* <div className="network_holder">
                      <p>{gasFee} ABBC gas fee</p>
                      <div className="networktype_holder">
                        {Object.keys(network_list).map((ele) => {
                          return (
                            <Networks
                              onClick={() => {
                                switchNetwork(ele);
                                // props.setNetwork(ele);
                              }}
                              disabled={network_list[ele].disabled}
                              active={props.network === ele}
                              value={ele}
                            ></Networks>
                          );
                        })}
                      </div>
                    </div> */}
                                        <div
                                            className={
                                                props.dark
                                                    ? "meta_addressholder dark"
                                                    : "meta_addressholder"
                                            }
                                        >
                                            <p>{locale.Receiving_Address}</p>
                                            <TextInput
                                                color={props.dark ? "#ffff" : "#231F20"}
                                                backgroundColor={props.dark ? "#152348" : "#ffff"}
                                                padding={10}
                                                fontSize={16}
                                                borderBottom={
                                                    props.dark ? "1px solid #f7991c" : "1px solid #231F20"
                                                }
                                                placeholder={
                                                    from === "ABBC"
                                                        ? `${locale.Receiving_Address_placeHolder_1} ${tokenName} ${locale.Receiving_Address_placeHolder_2}`
                                                        : `${locale.Receiving_Address_placeHolder_1} ABBC ${locale.Receiving_Address_placeHolder_2}`
                                                }
                                                disabled={from === "ABBC" ? true : false}
                                                value={
                                                    props.publicAdd && from === "ABBC"
                                                        ? props.publicAdd
                                                        : !props.publicAdd && from === "ABBC"
                                                            ? ""
                                                            : userWABBC
                                                }
                                                img={
                                                    from === "ABBC"
                                                        ? `${logo_url}`
                                                        : `./Images/${ABBC.logo}`
                                                }
                                                onChange={(val) => {
                                                    // debugger
                                                    if (from !== "ABBC") {
                                                        setUserWABBC(val);
                                                    }
                                                }}
                                            ></TextInput>
                                            {addressErr ? (
                                                <p className="errorText">{addressErr}</p>
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                props.dark
                                                    ? "swap_buttonholder dark"
                                                    : "swap_buttonholder"
                                            }
                                        >
                                            <p>
                                                {locale.Terms_1} <a style={{ cursor: "pointer" }} onClick={() => { window.open(`${process.env.PUBLIC_URL}/terms_of_use.pdf`) }}>{locale.Terms_2}</a>
                                                .
                                            </p>
                                            <Button
                                                loader={loader}
                                                disabled={
                                                    !!!converted || isNaN(Number(converted)) || loader
                                                }
                                                fontSize={20}
                                                fontWeight="700"
                                                color="#231F20"
                                                backgroundColor="#FFB048"
                                                label="SWAP"
                                                onClick={() => {
                                                    swap();
                                                    // navigate("/trans");
                                                }}
                                            ></Button>
                                            <div></div>
                                            {!manual ? <button onClick={() => { setManual(true) }} className={props.dark ? 'manual_button dark' : "manual_button"}>
                                                {locale.Read_Manual}
                                            </button> : null}
                                            {manual ? <><button onClick={() => ManualData.length > 0 ? ManualData.filter(x => x.name == props.network).map(res => {
                                                window.open(res.url)
                                                setManual(false)
                                            }) : ''} style={{ width: 100 }} className={props.dark ? 'manual_button dark' : "manual_button"}>
                                                ENG
                                            </button>
                                                <button onClick={() => {
                                                    window.open(`${process.env.PUBLIC_URL}/Swap_Manual_kr.pdf`)
                                                    setManual(false)

                                                }} style={{ width: 100 }} className={props.dark ? 'manual_button dark ml-3' : "manual_button ml-3"}>
                                                    한국어
                                                </button></> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        publicAdd: state.publicAdd,
        dark: state.dark,
        network: state.network,
        networks: state.networks,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        setAdd: (val) => {
            dispatch(Actions.setAdd(val));
        },
        setNetwork: (val) => {
            dispatch({ type: "SET_NETWORK", payLoad: val });
        },
    };
};
export default connect(mapStateToProps, mapActionsToProps)(Home);
