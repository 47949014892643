import React from 'react'
// import './Success.css'
import { BiArrowBack, BiCopyAlt } from 'react-icons/bi'
import {AiOutlineCloseCircle} from 'react-icons/ai'
import * as Toast from '../../Global/Toasts'
import { connect } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router';
import copy from 'copy-to-clipboard';
import Locale from '../../Global/Locale'
import locale from "../../Global/Locale/index";
const Fail = (props) => {
    const { state } = useLocation();
    let navigate = useNavigate();
    if(!state || !state.req_id || !state.message){
        return <Navigate to='/'></Navigate>
    }
    return (
        <div className={props.dark?'Success dark':"Success"}>
            <div className="container-fluid">
                <AiOutlineCloseCircle size={100} color='#f7991c'></AiOutlineCloseCircle>
            {/* <img src={`${process.env.PUBLIC_URL}${props.dark?"/Images/dark_tick.png":"/Images/Success.png"}`}></img> */}
            <h1>{Locale.Trans_Failed}</h1>
            <p>{state.message}</p>
            <div className='requestid_holder'>
                <h4>{locale.Request_ID}</h4>
                <div className='request_id'>
                    <p>{state.req_id}</p>
                    <div className="copy_holder"><BiCopyAlt size={25} onClick={() => {
                        copy(state.req_id)
                            Toast.successToast("Copied")
                        
                        
                    }} color='#B2B2B2'></BiCopyAlt></div>
                </div>
                <p>{locale.Success_info}</p>
            </div>
            </div>
         </div>
    )
                
}
const mapStateToProps = (state) => {
    return ({
        publicAdd: state.publicAdd,
        dark:state.dark
    })
}

export default connect(mapStateToProps)(Fail)