const initialState={
    publicAdd:"",
    dark:localStorage.getItem("mode")?(localStorage.getItem("mode")==="dark"?true:false):false,
    network:localStorage.getItem("network")?localStorage.getItem("network"):"BSC",
    networks:{}
}


const Reducer=(state=initialState,Action)=>{
    
    switch (Action.type) {
        case "SET_ADD":
            return {...state,publicAdd:Action.payLoad}
        case "SET_DARK":
            return {...state,dark:Action.payLoad}
            case "SET_NETWORK":
                return {...state,network:Action.payLoad}     
        case "SET_NETWORKS":
            return {...state,networks:{...Action.payLoad}}
        default:
            return state
    }
}

export default Reducer