import * as API from './Endpoints'
import Axios from 'axios'
export const getConversion = async (amount, deposit_currency, network) => {
    let data = await Axios.post(`${API.user_API}/conversion/`, { deposit_currency, amount, network })
    return data
}
export const swap = async (amount, address, deposit_currency, network) => {
    let data = await Axios.post(`${API.user_API}/swap/`, { deposit_currency, amount, address, network })
    return data
}
export const submit = async (params) => {
    let data = await Axios.post(`${API.user_API}/submit/`, { ...params })
    return data
}

export const getRequestDet = async (params) => {
    let data = await Axios.post(`${API.user_API}/fetchrequest/`, { ...params })
    return data
}
export const getStatus = async (params) => {
    let data = await Axios.post(`${API.user_API}/check_status/`, { ...params })
    return data
}
export const getNetworks = async () => {
    let data = await Axios.post(`${API.user_API}/fetchnetwork/`, {})
    return data
}
export const getPermissionNotification = async (header, body) => {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else {
        await Notification.requestPermission();

    }
}
export function redirectToMetamask() {
    if (/* 'ontouchstart' in window || 'onmsgesturechange' in window ||  */!window.ethereum) {
        window.open("https://metamask.app.link/dapp/abbcswap.com/")
        return true
    } else {
        return false
    }
}
export const BrowserNotificationHandler = async (header, body) => {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else {
        // await Notification.requestPermission();
        var options = {
            body: body,
            icon: `${process.env.PUBLIC_URL}/Images/abbc_logo.png`,
            dir: "ltr"
        };
        new Notification(header, options);
    }
}