import React, { useState } from 'react'
import "./Networks.css"
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import classNames from 'classnames'
const Networks = (props) => {
    const [tooltip, setToolTip] = useState(false)
    var networkclass = classNames(
        "Networks", { active: props.active, dark: props.dark }
    )
    if (props.disabled) {
        return (
            <>
                <div data-tip data-for='sadFace' onMouseEnter={() => { setToolTip(true) }} onMouseLeave={() => { setToolTip(false) }} /* onClick={() => { props.onClick() }} */ className={networkclass}>

                    <img src={props.img}></img> <p>{props.value}</p>

                </div>
                <ReactTooltip place="left" id='sadFace' type='info' effect='solid'>
                    <span>Coming Soon</span>
                </ReactTooltip>
            </>

        )
    }

    return (
        <>
            <div data-tip data-for={props.chainName} onClick={() => { props.onClick() }} className={networkclass}>
                <img src={props.img}></img> <p>{props.value}</p>
            </div>
            <ReactTooltip arrowColor={!props.dark ? "#15162a" : "#ffff"} className={props.dark ? 'tooltip dark' : 'tooltip'} place="top" id={props.chainName} type='light' effect='solid'>
                {props.maintenance ? <span>Under Maintenance. Sorry for the inconvenience caused</span> : <span>{props.chainName}</span>}

            </ReactTooltip>
        </>
    )
}

const mapStateToProps = (state) => {
    return ({
        publicAdd: state.publicAdd,
        dark: state.dark
    })
}

export default connect(mapStateToProps)(Networks)