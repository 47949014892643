import React, { useRef, useState, useEffect } from "react";
import "./Home.css";
import Button from "../../Components/Button";
import TextInput from "../../Components/TextInput";
import CoinPicker from "../../Components/CoinPicker";
import * as API from "../../Global/Utility";
import { Resposne } from "../../Global/ResponseDesc";
import * as _ from "lodash";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Networks from "../../Components/Networks";
import { AiOutlineSwap } from "react-icons/ai";
import * as Toast from "../../Global/Toasts";
import { Coins } from "../../Global/Coins";
import locale from "../../Global/Locale/index";
import * as Actions from "../../Global/Actions";
import BeatLoader from "react-spinners/BeatLoader";
import { formatUnits } from "@ethersproject/units";
import { ABBC } from "../../Global/Networks";
import { set } from "lodash";

const Swap = (props) => {
  let network_list = props.networks;

  let navigate = useNavigate();
  const [converted, setConverted] = useState(0.0);
  const [from, setFrom] = useState("ABBC");
  const [fromLabel, setFromLabel] = useState("ABBC");
  const [to, setTo] = useState(network_list[props.network].tokenName);
  const [toLabel, setToLabel] = useState(network_list[props.network].tokenName);
  const [gasFee, setGasFee] = useState("-");
  const amount = useRef(null);
  const [userWABBC, setUserWABBC] = useState("");
  const [loader, setLoader] = useState(false);
  const [gasError, setGasError] = useState("");
  const [addressErr, setAddressErr] = useState(false);
  const [rotate, setRotate] = useState(true);
  const [manual, setManual] = useState(false)
  useEffect(() => {
    setFrom("ABBC");
    setFromLabel("ABBC");
    setTo(network_list[props.network].tokenName);
    setToLabel(network_list[props.network].tokenName);
  }, [props.network]);
  const switchNetwork = async (val, status) => {
    if (window.ethereum) {
      if (!status) {
        if (window.ethereum.isCoin98) {
          Toast.errorToast("Please disable Coin98 extension")
        } else {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: network_list[val].chainID }],
            });
            return true
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: network_list[val].chainID,
                      chainName: network_list[val].chainName,
                      rpcUrls: [network_list[val].node],
                      nativeCurrency: {
                        name: network_list[val].metamask_symbol,
                        symbol: network_list[val].metamask_symbol,// 2-6 characters long
                        decimals: 18
                      },
                      blockExplorerUrls: [network_list[val].explorer_url]
                    },
                  ],
                });
              } catch (error) {
                if (error.code === -32602) {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: network_list[val].chainID }],
                  }).then(res => { }).catch(e => {
                    if (e.code == 4001) {
                      Toast.errorToast(e.message)
                    } else {
                      return
                    }
                  });
                  localStorage.setItem("network", val)
                  props.setNetwork(val);
                  convert(amount.current, from, val)
                  return
                }
                if (error.code === -32002) {
                  Toast.errorToast("Please confirm the pending request from metamask")

                } else {
                  Toast.errorToast(error.message)
                }
              }
            }
          }
        }
      } else {
        Toast.warningToast("Under Maintenance. Sorry for the inconvenience caused.")
      }
    } else {
      props.setNetwork(val);
    }
  };

  const convert = _.debounce(async (val, depCurr, networkVal) => {
    try {
      setLoader(true);
      if (val === "") {
        setLoader(false);
      }
      if (!isNaN(Number(val))) {
        if (Number(val) === 0) {
          setGasFee("-");
          setConverted(0);

          setLoader(false);
          return;
        }
        let convertRes = await API.getConversion(
          Number(val),
          depCurr,
          props.network
        );
        if (convertRes.data.code === 0) {
          setConverted(convertRes.data.data.receivable_amount);
          setGasFee(Number(convertRes.data.data.total_fee).toFixed(2));
          setLoader(false);
          setGasError("");
          return;
        }
        if (convertRes.data.code === 22024) {
          Toast.errorToast(convertRes.data.data.message)
          setLoader(false);
          return;
        }
        if (convertRes.data.code === 22023) {
          Toast.errorToast(convertRes.data.data.message)
          setLoader(false);
          return;
        }
        if (convertRes.data.code === 20020) {
          Toast.errorToast(convertRes.data.data.message)
          setLoader(false);
          return;
        }
        if (convertRes.data.code === 20001) {
          setGasError(convertRes.data.data.message);
          setConverted(0.0);
          setLoader(false);
          return;
        } else {
          Toast.warningToast(Resposne[convertRes.data.code].message);
          setConverted(0.0);
          setLoader(false);
          setGasError("");
        }
      } else {
        setGasError("Invalid Input");
        setLoader(false);
      }
    } catch (error) {
      Toast.errorToast(error.message);
      setLoader(false);
      setGasError("");
    }
  }, 1000);

  const swap = async () => {
    setLoader(true);
    try {
      if (!!!converted || isNaN(Number(converted))) {
        Toast.warningToast("Please enter valid amount");
        setLoader(false);
        return;
      }
      if (
        (from === "ABBC" && (props.publicAdd || userWABBC)) ||
        (from !== "ABBC" && userWABBC)
      ) {
        let deposit_currency;
        let network = props.network;
        let publicadd;
        if (from === "ABBC") {
          if (props.publicAdd) {
            publicadd = window.web3.utils.toChecksumAddress(props.publicAdd);
          } else {
            publicadd = userWABBC;
          }

          deposit_currency = "ABBC";
        } else {
          publicadd = userWABBC;
          deposit_currency = network_list[props.network].localName;
        }

        let swap = await API.swap(
          Number(amount.current),
          publicadd,
          deposit_currency,
          network
        );
        if (swap.data.code === 0) {
          navigate(
            `/trans/${swap.data.data.request_id}` /* , { state: { data: swap.data.data, coinid: from, deposit_currency, network } } */
          );
          setLoader(false);
        }
        if (swap.data.code === 20003) {
          setAddressErr("Please enter valid wallet address");
          setLoader(false);
        } else {
          Toast.errorToast(swap.data.data.message);
          setLoader(false);
        }
      } else {
        // if (from === 1) {
        //   setAddressErr("Please enter valid wallet address");
        //   setLoader(false);
        // } else {
        //   setAddressErr("Please enter valid wallet address");
        //   setLoader(false);
        // }
        if (from === "ABBC") {
          Toast.errorToast("Please Connect your wallet");
          setLoader(false);
        } else {
          setAddressErr("Please enter valid wallet address");
          setLoader(false);
        }
      }
    } catch (error) {
      Toast.errorToast(error.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    let check = API.redirectToMetamask()
    if (!check) {
      switchNetwork(props.network);
    }
  }, [props.network]);
  const changeHandler = (val) => {
    if (rotate) {
      document.getElementsByClassName("swapicon_holder")[0].style.transform =
        "translate(0, -50%) rotate(270deg)";
    } else {
      document.getElementsByClassName("swapicon_holder")[0].style.transform =
        "translate(0, -50%) rotate(90deg)";
    }
    let dep;
    if (from === "ABBC") {
      setTo("ABBC");
      setToLabel("ABBC");
      setFrom(network_list[props.network].tokenName);
      setFromLabel(network_list[props.network].tokenName);
      dep = network_list[props.network].localName;
    } else {
      setUserWABBC('')
      setTo(network_list[props.network].tokenName);
      setToLabel(network_list[props.network].tokenName);
      setFrom("ABBC");
      setFromLabel("ABBC");
      dep = "ABBC";
    }
    setRotate(!rotate);
    if (!isNaN(Number(amount.current)) && Number(amount.current) > 0) {
      convert(amount.current, dep);
    }
  };

  let { tokenName, localName, network, logo_url } = network_list[props.network];
  if (window.innerWidth > 992) {
    return <Navigate to="/"></Navigate>;
  }
  return (
    <>
      {/* <div className="home_holder"> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className={props.dark ? "home_right dark" : "home_right"}>
              {/* <h1>Swap</h1> */}
              <div className={props.dark ? "coin_holder dark" : "coin_holder"}>
                <div
                  onClick={() => {
                    changeHandler();
                  }}
                  className="swapicon_holder"
                >
                  <img src={"./Images/swap_icon.png"}></img>
                </div>
                <div className={props.dark ? "coin_item dark" : "coin_item"}>
                  <div className="coin_info">
                    <div className="coin_icon">
                      <img
                        src={
                          from === "ABBC"
                            ? `./Images/${ABBC.logo}`
                            : `./Images/wrapABBC.png`
                        }
                      ></img>
                      <p>{fromLabel}</p>
                    </div>
                    <h4>{fromLabel}</h4>
                  </div>
                  <div className="coin_trans">
                    <p>{locale.From}</p>
                    <TextInput
                      color={props.dark ? "#ffff" : "#231F20"}
                      backgroundColor={props.dark ? "#152348" : "#ffff"}
                      marginLeft={0}
                      placeholder="0.00"
                      align="left"
                      onChange={(val) => {
                        amount.current = val;
                        if (from === "ABBC") {
                          convert(val, from);
                        } else {
                          convert(val, localName);
                        }
                      }}
                    ></TextInput>
                  </div>
                </div>
                <div
                  className={props.dark ? "coin_item dark" : "coin_item"}
                  id="coin_itemswap_mob"
                >
                  <div className="coin_info">
                    <div className="coin_icon">
                      <img
                        src={
                          to === "ABBC"
                            ? `./Images/${ABBC.logo}`
                            : `./Images/wrapABBC.png`
                        }
                      ></img>
                      <p>{toLabel}</p>
                    </div>
                    <h4>{toLabel}</h4>
                  </div>
                  <div className="coin_trans">
                    <p>{locale.To}</p>
                    {!loader ? (
                      <h2>{Number(converted).toFixed(2)}</h2>
                    ) : (
                      <BeatLoader
                        color="#f7991c"
                        loading={loader}
                        size={8}
                      ></BeatLoader>
                    )}
                  </div>
                </div>
                {gasError ? <p className="errorText">{gasError}</p> : null}
              </div>

              <div className="address_holder">
                <div className="network_holder">
                  <p className="network_label">{locale.choose_network}</p>
                  <p>{gasFee} ABBC {locale.gas_fee}</p>
                </div>
                <div className="networktype_holder">
                  {Object.keys(network_list).map((ele) => {
                    return (
                      <Networks
                        onClick={() => {
                          switchNetwork(ele, network_list[ele].maintenance);
                          props.setNetwork(ele);
                        }}
                        disabled={network_list[ele].disabled}
                        active={props.network === ele}
                        value={ele}
                        img={network_list[ele].logo_url}
                        chainName={network_list[ele].chainName}
                      // maintenance={network_list[ele].maintenance}
                      ></Networks>
                    );
                  })}
                </div>

                <div
                  className={
                    props.dark
                      ? "meta_addressholder dark"
                      : "meta_addressholder"
                  }
                >
                  <p>{locale.Receiving_Address}</p>

                  <TextInput
                    borderBottom={
                      props.dark ? "1px solid #f7991c" : "1px solid #231F20"
                    }
                    color={props.dark ? "#ffff" : "#231F20"}
                    backgroundColor={props.dark ? "#152348" : "#ffff"}
                    padding={10}
                    fontSize={16}
                    placeholder={
                      from === "ABBC"
                        ? `Please enter your ${tokenName} address`
                        : "Please enter your ABBC address"
                    }

                    disabled={from === "ABBC" ? true : false}
                    // value={
                    //   props.publicAdd && from === "ABBC"
                    //     ? props.publicAdd
                    //     : userWABBC
                    // }
                    value={
                      props.publicAdd && from === "ABBC"
                        ? props.publicAdd
                        : !props.publicAdd && from === "ABBC"
                          ? ""
                          : userWABBC
                    }
                    img={
                      from === "ABBC" ? `${logo_url}` : `./Images/${ABBC.logo}`
                    }
                    onChange={(val) => {
                      if (from !== "ABBC") {
                        setUserWABBC(val);
                      }
                    }}
                  ></TextInput>
                  {addressErr ? (
                    <p className="errorText">{addressErr}</p>
                  ) : null}
                </div>
                <div
                  className={
                    props.dark ? "swap_buttonholder dark" : "swap_buttonholder"
                  }
                >
                  <p>
                    {locale.Terms_1} <a style={{ cursor: "pointer" }} onClick={() => { window.open(`${process.env.PUBLIC_URL}/terms_of_use.pdf`) }}>{locale.Terms_2}</a>.
                  </p>
                  <Button
                    loader={loader}
                    disabled={
                      !!!converted || isNaN(Number(converted)) || loader
                    }
                    fontSize={20}
                    fontWeight="700"
                    color="#231F20"
                    backgroundColor="#FFB048"
                    label="SWAP"
                    onClick={() => {
                      swap();
                      // navigate("/trans");
                    }}
                  ></Button>
                  <div></div>
                  {!manual ? <button onClick={() => { setManual(true) }} className={props.dark ? 'manual_button dark' : "manual_button"}>
                    {locale.Read_Manual}
                  </button> : null}
                  {manual ? <><button onClick={() => {
                    window.open(`${process.env.PUBLIC_URL}/Swap_Manual.pdf`)
                    setManual(false)

                  }} style={{ width: 100 }} className={props.dark ? 'manual_button dark' : "manual_button"}>
                    English
                  </button>
                    <button className={props.dark ? 'manual_button dark ml-3' : "manual_button ml-3"} onClick={() => {
                      window.open(`${process.env.PUBLIC_URL}/Swap_Manual_kr.pdf`)
                      setManual(false)

                    }} style={{ width: 100 }} >
                      한국어
                    </button></> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    publicAdd: state.publicAdd,
    dark: state.dark,
    network: state.network,
    networks: state.networks,
  };
};
const mapActionsToProps = (dispatch) => {
  return {
    setAdd: (val) => {
      dispatch(Actions.setAdd(val));
    },
    setNetwork: (val) => {
      dispatch({ type: "SET_NETWORK", payLoad: val });
    },
  };
};
export default connect(mapStateToProps, mapActionsToProps)(Swap);
